import { Injectable } from '@angular/core'
import { HttpBackend, HttpClient } from '@angular/common/http'
import { FleetFeedback } from '@boosterfuels/types/types-ts'
import { environment as ENV } from '../../../environments/environment'

@Injectable()
export class FleetFeedbackApi {
  private httpNoInterceptors: HttpClient

  constructor(
    private http: HttpClient,
    handler: HttpBackend
  ) {
    this.httpNoInterceptors = new HttpClient(handler)
  }

  findByFleetOrderId(params: { fleetOrderId: string }) {
    return this.http.get<{ fleetFeedback: FleetFeedback }>(
      '/FleetFeedback/findByFleetOrderId',
      { params }
    )
  }

  create(data: Partial<FleetFeedback> & { hash?: string }) {
    return this.http.post<{ fleetFeedback: FleetFeedback }>(
      '/FleetFeedback/create',
      data
    )
  }

  update(data: Partial<FleetFeedback>) {
    return this.http.put<{ fleetFeedback: FleetFeedback }>(
      '/FleetFeedback/update',
      data
    )
  }

  sendServiceFeedback(data: { hash: string; props: any }) {
    const url = `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetFeedback/sendServiceFeedback`
    return this.httpNoInterceptors.post<any>(url, data)
  }
}
